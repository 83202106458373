import React from 'react';
import './Home.css';

const Home = () => {
  return (
    <div id="home" className="home-container">
      <h1 className="home-highlight">The next Generation</h1>
      <h1 className="home-highlight">Decentralized</h1>
      <h1 className="home-highlight home-gradient">Automated</h1>
      <p>Let's conquer daily challenges together! Discover how we can streamline your efforts for maximum profit with minimal stress.</p>
    </div>
  );
}

export default Home;
