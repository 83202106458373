import React from 'react';
import './LightControl.css'; // Lembre-se de criar e importar o arquivo CSS para estilizar esta página

const LightControl = () => {
  return (
    <div id="lightControl" className="section">
      <h1>Light Control</h1>
      <p>Turn lights on and off remotely or schedule timings to optimize energy usage and convenience.</p>
      <h2>Advantages of Light Control</h2>
      <ul>
        <li>Remote control of lights for convenience and security.</li>
        <li>Scheduled timings to automatically turn lights on and off.</li>
        <li>Temperature control integration for comfort and energy savings.</li>
        <li>Door access control for added security.</li>
        <li>Energy efficiency leading to cost savings.</li>
        <li>Time-saving automation for daily routines.</li>
      </ul>
    </div>
  );
}

export default LightControl;
