import React, { useState } from 'react';
import './Login.css'; // Lembre-se de criar e importar o arquivo CSS para estilizar esta página

const Login = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Lógica de autenticação aqui (exemplo: enviar para backend, validar etc.)
    console.log('Form submitted:', formData);
    // Limpar os campos após o envio (opcional)
    setFormData({
      username: '',
      password: ''
    });
  };

  return (
    <div id="login" className="section">
      <h1>Login</h1>
      <form onSubmit={handleSubmit} className="login-form">
        <label htmlFor="username">Username:</label>
        <input
          type="text"
          id="username"
          name="username"
          value={formData.username}
          onChange={handleChange}
          required
        />
        <label htmlFor="password">Password:</label>
        <input
          type="password"
          id="password"
          name="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
        <div className="button-container">
          <button type="submit" className="login-button">Login</button>
        </div>
      </form>
    </div>
  );
}

export default Login;
