import React from 'react';
import './Monitoring.css'; // Lembre-se de criar e importar o arquivo CSS para estilizar esta página

const Monitoring = () => {
  return (
    <div id="monitoring" className="section">
      <h1>Monitoring</h1>
      <p>Monitor the status of doors and other devices to enhance security and efficiency.</p>
      <h2>Advantages of Monitoring</h2>
      <ul>
        <li>Real-time monitoring of door status.</li>
        <li>Energy usage tracking for cost optimization.</li>
        <li>Enhanced security with access logs and history.</li>
        <li>Remote monitoring capabilities for convenience.</li>
        <li>Automated alerts and notifications for immediate response.</li>
      </ul>
    </div>
  );
}

export default Monitoring;
