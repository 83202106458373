import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Home from './components/Home';
import Monitoring from './components/Monitoring';
import LightControl from './components/LightControl';
import Contact from './components/Contact';
import Login from './components/Login';
import './App.css';

const ScrollToSection = ({ sectionId }) => {
  const location = useLocation();

  React.useEffect(() => {
    if (location.hash === `#${sectionId}`) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, sectionId]);

  return null;
};

const MainPage = () => (
  <div>
    <ScrollToSection sectionId="home" />
    <ScrollToSection sectionId="monitoring" />
    <ScrollToSection sectionId="lightControl" />
    <ScrollToSection sectionId="contact" />
    <Home />
    <Monitoring />
    <LightControl />
    <Contact />
  </div>
);

function App() {
  return (
    <Router>
      <div className="App">
        <nav>
          <div className="company-name">MGF System</div>
          <ul>
            <li><Link to="/#home">Home</Link></li>
            <li><Link to="/#monitoring">Monitoring</Link></li>
            <li><Link to="/#lightControl">Light Control</Link></li>
            <li><Link to="/#contact">Contact</Link></li>
            <li><Link to="/login">Login</Link></li>
          </ul>
        </nav>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<Login />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
